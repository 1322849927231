<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <loader-page v-if="isLoading"></loader-page>
            <template v-else>
                <!-- Filter -->
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-4">
                        <form @submit.prevent="filterCompany" class="uk-width-1-1">
                            <div class="uk-inline uk-width-1-1">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                                <input
                                    class="uk-input uk-width-1-1"
                                    type="text"
                                    placeholder="Search company name"
                                    :disabled="disabledFilterAndCreateNewCompany"
                                    v-model="meta.name"
                                >
                            </div>
                        </form>
                    </div>
                    <div class="uk-width-1-4">
                        <form @change.prevent="filterCompany">
                            <select class="uk-select uk-width-1-1" :disabled="disabledFilterAndCreateNewCompany" v-model="meta.is_partner">
                                <option :value="null">Select Partnership</option>
                                <option :value="true">Partner</option>
                                <option :value="false">Non Partner</option>
                            </select>
                        </form>
                    </div>
                    <div class="uk-width-1-4">
                        <form @change.prevent="filterCompany">
                            <select class="uk-select uk-width-1-1" :disabled="disabledFilterAndCreateNewCompany" v-model="meta.ascending">
                                <option :value="null">Select Sort</option>
                                <option :value="true">Ascending</option>
                                <option :value="false">Descending</option>
                            </select>
                        </form>
                    </div>
                    <div v-if="!consist(user_cred.roles, ['admin-operation','user-management','user-marketing'])" class="uk-width-1-4">
                        <button
                            type="button"
                            class="uk-button uk-width-1-1"
                            :class="[disabledFilterAndCreateNewCompany ? 'uk-button-default' : 'uk-button-primary']"
                            :disabled="disabledFilterAndCreateNewCompany"
                            @click="
                                showCreateOrEditCompanyModalBox({
                                    actionType: 'create',
                                    id: null,
                                    name: '',
                                    seoUrl: '',
                                    companyIndustryId: null,
                                    packageData: null,
                                    address: '',
                                    website: '',
                                    description: '',
                                    logo: null,
                                    isPartner: null,
                                    attendanceMethod: [],
                                    isTest: false,
                                    overtimeType: null,
                                })
                            "
                        >Create</button>
                    </div>
                    <div v-else class="uk-width-1-4"></div>
                </div>
                <!-- End filter -->

                <!-- Reorder company list -->
                <div v-if="consist(user_cred.roles, ['qwe123', 'superadmin-tech', 'superadmin-product', 'admin-bd'])" class="uk-grid-small" uk-grid>
                    <template v-if="reorderMode">
                        <template v-if="buttonReorderLoading">
                            <div class="uk-width-1-5"></div>
                            <div class="uk-width-1-5"></div>
                            <div class="uk-width-1-5"></div>
                            <div class="uk-width-1-5"></div>
                            <div class="uk-width-1-5">
                                <button
                                    type="button"
                                    class="uk-button uk-button-default uk-width-1-1"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.5"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <div class="uk-width-1-5"></div>
                            <div class="uk-width-1-5"></div>
                            <div class="uk-width-1-5"></div>
                            <div class="uk-width-1-5">
                                <button 
                                    type="button"
                                    class="uk-button uk-button-default uk-width-1-1"
                                    @click="doCancelReorderCompanyList"
                                >Cancel</button>
                            </div>
                            <div class="uk-width-1-5">
                                <button
                                    type="button"
                                    class="uk-button uk-width-1-1"
                                    :class="[onMoveCompanyItem === true ? 'uk-button-default ' : 'uk-button-primary ']"
                                    :disabled="onMoveCompanyItem === true"
                                    @click="doSaveReorderCompanyList"
                                >Save Changes</button>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="uk-width-1-5"></div>
                        <div class="uk-width-1-5"></div>
                        <div class="uk-width-1-5"></div>
                        <div class="uk-width-1-5"></div>
                        <div class="uk-width-1-5">
                            <button
                                type="button"
                                class="uk-button uk-button-primary uk-width-1-1"
                                @click="reorderCompanies"
                            >Reorder</button>
                        </div>
                    </template>
                </div>
                <!-- End reorder company list -->

                <!-- Table company list -->
                <div class="uk-card uk-card-default uk-margin-top">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th class="uk-text-center uk-table-shrink">No</th>
                                    <th class="uk-text-center">Company Name</th>
                                    <th class="uk-text-center">Industry</th>
                                    <th class="uk-text-center uk-width-1-3">Description</th>
                                    <th class="uk-text-center">Status</th>
                                    <th class="uk-text-center">Package</th>
                                    <th class="uk-text-center uk-width-small">Action</th>
                                </tr>
                            </thead>
                            <loading v-if="tableLoading" :colspan="7"></loading>
                            <template v-else>
                                <template v-if="reorderMode">
                                    <draggable
                                        v-if="companyData.docs.length > 0"
                                        v-model="companyData.docs"
                                        tag="tbody"
                                        @change="onChangeDraggedCompany"
                                    >
                                        <tr
                                            v-for="(company, index) in companyData.docs"
                                            :key="index"
                                            :draggable="reorderMode"
                                            :class="[reorderMode === true ? 'box' : '']"
                                        >
                                            <td class="uk-text-center uk-table-shrink">
                                                {{ autoIncrementNumberPagination({
                                                    pagingCounter: companyData.pagingCounter,
                                                    index
                                                }) }}
                                            </td>
                                            <td class="uk-text-center">{{ company.name || '-' }} <span v-if="company.is_test" style="color:#FF3737">(Test)</span></td>
                                            <td class="uk-text-center">{{ company.company_industry && company.company_industry.name ? company.company_industry.name : '-' }}</td>
                                            <td class="uk-text-center uk-width-1-3">{{ company.description || '-' }}</td>
                                            <td class="uk-text-center">
                                                <div class="label uk-margin-auto" :style="`${statusColor({ status: company.status })}`">
                                                    {{ statusLabel({ status: company.status }) }}
                                                </div>
                                            </td>
                                            <td class="uk-text-center">{{ company.package || '-' }}</td>
                                            <td class="uk-width-small uk-text-center">
                                                <img :src="`${images}/arrow-bidirectional-up-down-12-filled.svg`" alt="Drag drop icon" class="drag-drop-icon">
                                            </td>
                                        </tr>
                                    </draggable>
                                    <empty-table v-else :colspan="7" />
                                </template>
                                <template v-else>
                                    <tbody v-if="companyData.docs.length > 0">
                                        <tr v-for="(company, index) in companyData.docs" :key="index">
                                            <td class="uk-text-center uk-table-shrink">
                                                {{ autoIncrementNumberPagination({
                                                    pagingCounter: companyData.pagingCounter,
                                                    index
                                                }) }}
                                            </td>
                                            <td class="uk-text-center">{{ company.name || '-' }} <span v-if="company.is_test" style="color:#FF3737">(Test)</span></td>
                                            <td class="uk-text-center">{{ company.company_industry && company.company_industry.name ? company.company_industry.name : '-' }}</td>
                                            <td class="uk-text-center uk-width-1-3">{{ company.description || '-' }}</td>
                                            <td class="uk-text-center">
                                                <div class="label uk-margin-auto" :style="`${statusColor({ status: company.status })}`">
                                                    {{ statusLabel({ status: company.status }) }}
                                                </div>
                                            </td>
                                            <td class="uk-text-center">{{ company.package || '-' }}</td>
                                            <td class="uk-text-center uk-width-small">
                                                <button
                                                    type="button"
                                                    class="uk-button uk-button-small uk-button-default"
                                                >Actions</button>
                                                <div uk-dropdown="mode: click">
                                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                        <li><a @click="gotoCompanyDetail({ companyId: company._id })">Detail</a></li>
                                                        <template v-if="!consist(user_cred.roles, ['admin-operation','user-management','user-marketing'])">
                                                            <li>
                                                                <a
                                                                    v-clipboard:copy="company._id"
                                                                    v-clipboard:success="onCopy"
                                                                >Copy ID</a>
                                                            </li> 
                                                            <li class="uk-nav-divider"></li>
                                                            <li class="uk-nav-header">Data Action</li>
                                                            <li>
                                                                <a
                                                                    @click="
                                                                        showCompanyLogsModalBox({
                                                                            actionType: 'companyDetailLogs',
                                                                            id: company._id
                                                                        })
                                                                    "
                                                                >Show Logs</a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    @click="
                                                                        showUpdateCompanyStatusModalBox({
                                                                            actionType: 'updateCompanyStatus',
                                                                            id: company._id,
                                                                            companyStatus: company.status,
                                                                            picBds: company.pic_bds,
                                                                            picOps: company.pic_ops
                                                                        })
                                                                    "
                                                                >Update Status</a>
                                                            </li>
                                                            <li v-if="company.status > 0 && company.status < 5 && consist(user_cred.roles, ['superadmin-product','qwe123'])">
                                                                <a
                                                                    @click="
                                                                        showChangeCompanyPicModalBox({
                                                                            actionType: 'changeCompanyPic',
                                                                            id: company._id,
                                                                            companyStatus: company.status,
                                                                            picBds: company.pic_bds,
                                                                            picOps: company.pic_ops,
                                                                            superadminId: company.superadmin && company.superadmin._id ? company.superadmin._id : null 
                                                                        })
                                                                    "
                                                                >Change PIC / Superadmin</a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    @click="
                                                                        showCreateOrEditCompanyModalBox({
                                                                            actionType: 'edit',
                                                                            id: company._id,
                                                                            name: company.name,
                                                                            seoUrl: company.seo_url,
                                                                            companyIndustryId: company.company_industry && company.company_industry._id ? company.company_industry._id : null,
                                                                            packageData: company.package,
                                                                            address: company.address,
                                                                            website: company.website,
                                                                            description: company.description,
                                                                            logo: company.logo,
                                                                            isPartner: company.is_partner,
                                                                            attendanceMethod: company.attendance_method,
                                                                            isTest: company.is_test,
                                                                            overtimeType: company.overtime_type,
                                                                        })
                                                                    "
                                                                >Edit</a>
                                                            </li>
                                                            <li>
                                                                <a 
                                                                    @click="
                                                                        showConfirmationDeleteCompanyModalBox({
                                                                            actionType: 'delete',
                                                                            id: company._id,
                                                                            name: company.name,
                                                                            description: company.description
                                                                        })
                                                                    "
                                                                >Delete</a>
                                                            </li>
                                                            <li class="uk-nav-divider"></li>
                                                            <li class="uk-nav-header">Open API Access Token</li>
                                                            <li>
                                                                <a
                                                                    @click="
                                                                        showViewAccessTokenModalBox({
                                                                            actionType: 'viewAccessToken',
                                                                            id: company._id,
                                                                            name: company.name
                                                                        })
                                                                    "
                                                                >View Access Token</a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    @click="
                                                                        showCompanyIpWhitelistModalBox({
                                                                            actionType: 'companyIpWhitelist',
                                                                            id: company._id,
                                                                            ipWhitelist: company.ip_whitelist
                                                                        })
                                                                    "
                                                                >Company IP Whitelist</a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <empty-table v-else :colspan="7" />
                                </template>
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="companyData.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                        :disabled-change-page-action="disabledFilterAndCreateNewCompany"
                    ></pagination>
                </div>
                <!-- End table company list -->

                <!-- Create or edit company modal box -->
                <div id="create-or-edit-company-modal-box" class="uk-flex-top create-or-edit-company-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button
                            class="uk-modal-close-default"
                            type="button"
                            @click="
                                resetSelectedCompanyData()
                                resetCompanyLogoAttachment()
                                resetTempCompanyLogoAttachment()
                                errors.clear()
                            " 
                            uk-close
                        ></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Company Form</h2>
                        </div>
                        <form @submit.prevent="createOrEditCompany">
                            <div class="uk-padding-small">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        <label for="companyName" class="uk-form-label">Company Name <span class="uk-text-danger">*</span></label>
                                        <input
                                            id="companyName"
                                            name="companyName"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            placeholder="Name"
                                            v-model="selectedCompanyData.name"
                                            v-validate="{
                                                required: true
                                            }"
                                        >
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has('companyName')">{{ errors.first('companyName') }}</span>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="seoUrl" class="uk-form-label">SEO URL <span class="uk-text-danger">*</span></label>
                                        <input
                                            id="seoUrl"
                                            name="seoUrl"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            placeholder="contoh seo: nama-perusahaan-nama-brand-kalau-ada"
                                            v-model="selectedCompanyData.seo_url"
                                            v-validate="{
                                                required: true
                                            }"
                                        >
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has('seoUrl')">{{ errors.first('seoUrl') }}</span>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="companyIndustry" class="uk-form-label">Company Industry <span class="uk-text-danger">*</span></label>
                                        <multiselect
                                            id="companyIndustry"
                                            name="companyIndustry"
                                            track-by="name"
                                            label="name"
                                            :allow-empty="false"
                                            placeholder="Select company industry"
                                            :options="industryOptions"
                                            v-model="selectedCompanyData.company_industry_id"
                                            v-validate="{
                                                required: true
                                            }"
                                        ></multiselect>
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has('companyIndustry')">{{ errors.first('companyIndustry') }}</span>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="package" class="uk-form-label">Package <span class="uk-text-danger">*</span></label>
                                        <multiselect
                                            id="package"
                                            name="package"
                                            track-by="name"
                                            label="name"
                                            :allow-empty="false"
                                            placeholder="Select package"
                                            :options="packageOptions"
                                            v-model="selectedCompanyData.package"
                                            v-validate="{
                                                required: true
                                            }"
                                        ></multiselect>
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has('package')">{{ errors.first('package') }}</span>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="address" class="uk-form-label">Address <span class="uk-text-danger">*</span></label>
                                        <input
                                            id="address"
                                            name="address"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            placeholder="Address"
                                            v-model="selectedCompanyData.address"
                                            v-validate="{
                                                required: true
                                            }"
                                        >
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has('address')">{{ errors.first('address') }}</span>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="website" class="uk-form-label">Website <span class="uk-text-danger">*</span></label>
                                        <input
                                            id="website"
                                            name="website"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            placeholder="Website"
                                            v-model="selectedCompanyData.website"
                                            v-validate="{
                                                required: true,
                                                url: true
                                            }"
                                        >
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has('website')">{{ errors.first('website') }}</span>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="description" class="uk-form-label">Description <span class="uk-text-danger">*</span></label>
                                        <input
                                            id="description"
                                            name="description"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            placeholder="Description"
                                            v-model="selectedCompanyData.description"
                                            v-validate="{
                                                required: true
                                            }"
                                        >
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has('description')">{{ errors.first('description') }}</span>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="companyLogo" class="uk-form-label">Company Logo</label>
                                        <div v-if="companyLogoAttachment.url && companyLogoAttachment.file" id="companyLogo">
                                            <div class="uk-inline">
                                                <img
                                                    :src="companyLogoAttachment.url"
                                                    alt="Group logo preview"
                                                    class="uk-width-auto uk-height-medium image-preview"
                                                >
                                                <div v-if="`${images}/close.svg`" class="uk-position-top-right">
                                                    <button
                                                        type="button"
                                                        class="icon-close-button"
                                                        @click="resetCompanyLogoAttachment"
                                                    >
                                                        <img :src="`${images}/close.svg`" alt="Close icon">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else id="companyLogo">
                                            <button
                                                type="button"
                                                class="uk-button uk-button-primary uk-border-rounded uk-flex uk-flex-middle uk-flex-between"
                                                @click="triggerUploadCompanyLogo"
                                            >
                                                <span><img :src="`${images}/cloud-upload-white.svg`" alt="Cloud upload icon"></span>
                                                <span class="uk-margin-small-left">Upload Logo</span>
                                                <input
                                                    name="companyLogo"
                                                    type="file"
                                                    ref="companyLogo"
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    style="display: none;"
                                                    @change="previewCompanyLogo({ event: $event })"
                                                >
                                            </button>
                                        </div>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="partnership" class="uk-form-label">Partnership <span class="uk-text-danger">*</span></label>
                                        <multiselect
                                            id="partnership"
                                            name="partnership"
                                            track-by="name"
                                            label="name"
                                            :allow-empty="false"
                                            placeholder="Select partnership"
                                            :options="partnershipOptions"
                                            v-model="selectedCompanyData.is_partner"
                                            v-validate="{
                                                required: true
                                            }"
                                        ></multiselect>
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has('partnership')">{{ errors.first('partnership') }}</span>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="attendanceMethod" class="uk-form-label">Attendance Method</label>
                                        <multiselect
                                            id="attendanceMethod"
                                            track-by="name"
                                            label="name"
                                            :multiple="true"
                                            :close-on-select="false"
                                            placeholder="Select attendance method"
                                            :options="attendanceMethodOptions"
                                            v-model="selectedCompanyData.attendance_method"
                                        ></multiselect>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="overtimeType" class="uk-form-label">Overtime Type</label>
                                        <multiselect
                                            id="overtimeType"
                                            name="overtimeType"
                                            track-by="name"
                                            label="name"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :options="overtimeTypeOptions"
                                            v-model="selectedCompanyData.overtime_type"
                                        ></multiselect>
                                    </div>
                                    <div v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','qwe123'])" class="uk-width-1-1">
                                        <label><input
                                            class="uk-checkbox"
                                            type="checkbox"
                                            v-model="selectedCompanyData.is_test"
                                        > Is Test</label>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                                <button
                                    v-if="buttonSaveModalBoxLoading"
                                    type="button"
                                    class="uk-button uk-button-default"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.5"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledButtonCreateOrEditCompany ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledButtonCreateOrEditCompany"
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End create or edit company modal box -->

                <!-- Confirmation delete company modal box -->
                <div id="confirmation-delete-company-modal-box" class="uk-flex-top confirmation-delete-company-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button
                            class="uk-modal-close-default"
                            type="button"
                            uk-close
                            @click="
                                resetSelectedCompanyData()
                                resetCompanyLogoAttachment()
                                resetTempCompanyLogoAttachment()
                                errors.clear()
                            "
                        ></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Delete Company ?</h2>
                        </div>
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <h4 class="uk-margin-remove">Company Name:</h4>
                                    <p class="uk-margin-remove uk-margin-small-top">{{ selectedCompanyData.name || '-' }}</p>
                                </div>
                                <div class="uk-width-1-1">
                                    <h4 class="uk-margin-remove">Description:</h4>
                                    <p class="uk-margin-remove uk-margin-small-top">{{ selectedCompanyData.description || '-' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="buttonDeleteModalBoxLoading"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="uk-button"
                                :class="[disabledButtonDeleteCompany ? 'uk-button-default' : 'uk-button-danger']"
                                :disabled="disabledButtonDeleteCompany"
                                @click="doDeleteCompany"
                            >Delete</button>
                        </div>
                    </div>
                </div>
                <!-- End confirmation delete company modal box -->

                <!-- Company logs modal box -->
                <div id="company-logs-modal-box" class="uk-flex-top company-logs-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button
                            class="uk-modal-close-default"
                            type="button"
                            uk-close
                            @click="hideCompanyLogsModalBox"
                        ></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Detail Logs</h2>
                        </div>
                        <div v-if="modalBoxLoading" class="uk-modal-body uk-flex uk-flex-middle uk-flex-center">
                            <span uk-spinner="ratio: 2"></span>
                        </div>
                        <div v-else class="uk-modal-body" uk-overflow-auto>
                            <div v-if="companyLogsData.docs.length > 0" class="uk-grid-medium" uk-grid>
                                <div
                                    v-for="(log, index) in companyLogsData.docs"
                                    :key="index"
                                    class="uk-width-1-1"
                                >
                                    <template v-if="!log.data_before">
                                        <h4 class="uk-margin-remove">{{ formatDatetime({ date: log.updated_at }) }} - By: {{ log.user_id && log.user_id.fullname ? log.user_id.fullname : '-' }}</h4>
                                        <p class="uk-margin-remove uk-margin-small-top">Company just created!</p>
                                    </template>
                                    <template v-else>
                                        <h4 class="uk-margin-remove">{{ formatDatetime({ date: log.updated_at }) }} - By: {{ log.user_id && log.user_id.fullname ? log.user_id.fullname : '-' }}</h4>
                                        <div v-if="log && log.change && log.change.length > 0" class="uk-grid-small" uk-grid>
                                            <div
                                                v-for="(item, i) in log.change"
                                                :key="i"
                                                class="uk-width-1-1"
                                            > 
                                                <div v-if="item == 'status'">
                                                    <p class="uk-margin-remove uk-text-bold">{{ item }}:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ statusLabel({ status: log.data_before[item] }) || '-' }} 
                                                        >> 
                                                        {{ statusLabel({ status: log.data_after[item] }) || '-' }}
                                                    </p>
                                                </div>
                                                <div v-else-if="item == 'attendance_method'">
                                                    <p class="uk-margin-remove uk-text-bold">{{ item }}:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ attendanceMethodLabel({ logData: log.data_before[item] }) || '-' }} 
                                                        >> 
                                                        {{ attendanceMethodLabel({ logData: log.data_after[item] }) || '-' }}
                                                    </p>
                                                </div>
                                                <div v-else-if="item == 'company_industry_id'">
                                                    <p class="uk-margin-remove uk-text-bold">Industry:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ industryLabel({ companyIndustryId: log.data_before[item] }) || '-' }} 
                                                        >> 
                                                        {{ industryLabel({ companyIndustryId: log.data_after[item] }) || '-' }}
                                                    </p>
                                                </div>
                                                <div v-else-if="item == 'pic_bd_id'">
                                                    <p class="uk-margin-remove uk-text-bold">PIC BD:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ picBDLabel({ bdId: log.data_before[item] }) || '-' }} 
                                                        >> 
                                                        {{ picBDLabel({ bdId: log.data_after[item] }) || '-' }}
                                                    </p>
                                                </div>
                                                <div v-else-if="item == 'pic_op_id'">
                                                    <p class="uk-margin-remove uk-text-bold">PIC OP:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ picOPLabel({ opId: log.data_before[item] }) || '-' }} 
                                                        >> 
                                                        {{ picOPLabel({ opId: log.data_after[item] }) || '-' }}
                                                    </p>
                                                </div>
                                                <div v-else-if="item == 'pic_bd_ids'">
                                                    <p class="uk-margin-remove uk-text-bold">PIC BDs:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ picBDsLabel({ data: log.data_before[item] }) || '-' }} 
                                                        >> 
                                                        {{ picBDsLabel({ data: log.data_after[item] }) || '-' }}
                                                    </p>
                                                </div>
                                                <div v-else-if="item == 'pic_op_ids'">
                                                    <p class="uk-margin-remove uk-text-bold">PIC OPs:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ picOPsLabel({ data: log.data_before[item] }) || '-' }} 
                                                        >> 
                                                        {{ picOPsLabel({ data: log.data_after[item] }) || '-' }}
                                                    </p>
                                                </div>
                                                <div v-else-if="item == 'superadmin_id'">
                                                    <p class="uk-margin-remove uk-text-bold">Superadmin:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ superadminLabel({ superadminId: log.data_before[item] }) || '-' }} 
                                                        >> 
                                                        {{ superadminLabel({ superadminId: log.data_after[item] }) || '-' }}
                                                    </p>
                                                </div>
                                                <div v-else>
                                                    <p class="uk-margin-remove uk-text-bold">{{ item }}:</p>
                                                    <p class="uk-margin-remove">
                                                        {{ log.data_before[item] || '-' }} 
                                                        >> 
                                                        {{ log.data_after[item] || '-' }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p v-else class="uk-margin-remove">-</p>
                                    </template>
                                </div>
                            </div>
                            <div v-else>
                                <h4 class="uk-margin-top">This company never been updated!</h4>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                type="button"
                                class="uk-button uk-button-primary"
                                @click="hideCompanyLogsModalBox"
                            >OK</button>
                        </div>
                    </div>
                </div>
                <!-- End company log modal box -->

                <!-- Update company status modal box -->
                <div id="update-company-status-modal-box" class="uk-flex-top update-company-status-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button
                            class="uk-modal-close-default"
                            type="button"
                            uk-close
                            @click="
                                resetSelectedCompanyData()
                                resetCompanyLogoAttachment()
                                resetTempCompanyLogoAttachment()
                                errors.clear()
                            "
                        ></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Change Status</h2>
                        </div>
                        <form @submit.prevent="doUpdateCompanyStatus">
                            <div class="uk-grid-small uk-padding-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="companyStatus" class="uk-form-label">Change To</label>
                                    <multiselect
                                        id="companyStatus"
                                        track-by="name"
                                        label="name"
                                        :close-on-select="true"
                                        :allow-empty="false"
                                        placeholder="Select status"
                                        :options="companyStatusOptions"
                                        v-model="selectedCompanyData.status"
                                    ></multiselect>
                                </div>
                                <div v-if="selectedCompanyData.status && selectedCompanyData.status.value == 1" class="uk-width-1-1">
                                    <label for="picBds" class="uk-form-label">PICs Bussiness Development</label>
                                    <multiselect
                                        id="picBds"
                                        track-by="_id"
                                        label="fullname"
                                        :close-on-select="false"
                                        :multiple="true"
                                        :limit="2"
                                        placeholder="Select PIC Bussiness Development"
                                        :options="picBdOptions"
                                        v-model="selectedCompanyData.pic_bd_ids"
                                        :disabled="bd_exist && consist(user_cred.roles, ['admin-bd'])"
                                    ></multiselect>
                                </div>
                                <div v-if="selectedCompanyData.status && selectedCompanyData.status.value == 1" class="uk-width-1-1">
                                    <label for="picOps" class="uk-form-label">PICs Operation</label>
                                    <multiselect
                                        id="picOps"
                                        track-by="_id"
                                        label="fullname"
                                        :close-on-select="false"
                                        :multiple="true"
                                        :limit="2"
                                        placeholder="Select PICs Operation"
                                        :options="picOperationOptions"
                                        v-model="selectedCompanyData.pic_op_ids"
                                        :disabled="op_exist && consist(user_cred.roles, ['admin-bd'])"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                                <button
                                    v-if="buttonSaveModalBoxLoading"
                                    type="button"
                                    class="uk-button uk-button-default"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.5"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledButtonUpdateCompanyStatus ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledButtonUpdateCompanyStatus"
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End update company status modal box -->

                <!-- Change company PIC modal box -->
                <div id="change-company-pic-modal-box" class="uk-flex-top change-company-pic-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Change PIC</h2>
                        </div>
                        <form @submit.prevent="doUpdateCompanyStatus">
                            <div class="uk-grid-small uk-padding-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="picBd" class="uk-form-label">PICs Bussiness Development</label>
                                    <multiselect
                                        id="picBd"
                                        track-by="_id"
                                        label="fullname"
                                        :close-on-select="false"
                                        :multiple="true"
                                        :limit="2"
                                        placeholder="Select PIC BD"
                                        :options="picBdOptions"
                                        v-model="selectedCompanyData.pic_bd_ids"
                                    ></multiselect>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="picOperation" class="uk-form-label">PICs Operation</label>
                                    <multiselect
                                        id="picOperation"
                                        track-by="_id"
                                        label="fullname"
                                        :close-on-select="false"
                                        :multiple="true"
                                        :limit="2"
                                        placeholder="Select PIC Operation"
                                        :options="picOperationOptions"
                                        v-model="selectedCompanyData.pic_op_ids"
                                    ></multiselect>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="superadmin" class="uk-form-label">Superadmin</label>
                                    <multiselect
                                        id="superadmin"
                                        track-by="_id"
                                        label="fullname"
                                        placeholder="Select superadmin"
                                        :options="superadminOptions"
                                        v-model="selectedCompanyData.superadmin_id"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                                <button
                                    v-if="buttonSaveModalBoxLoading"
                                    type="button"
                                    class="uk-button uk-button-default"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.5"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button 
                                    v-else
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledButtonUpdateCompanyStatus ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledButtonUpdateCompanyStatus"
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End change company PIC modal box -->

                <!-- View access token modal box -->
                <div id="view-access-token-modal-box" class="uk-flex-top view-access-token-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button
                            class="uk-modal-close-default"
                            type="button"
                            @click="hideViewAccessTokenModalBox"
                            uk-close
                        ></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Open API Access Token</h2>
                        </div>
                        <div v-if="modalBoxLoading" class="uk-modal-body uk-flex uk-flex-middle uk-flex-center">
                            <span uk-spinner="ratio: 2"></span>
                        </div>
                        <div v-else class="uk-modal-body">
                            <div v-if="accessTokenData.docs.length > 0">
                                <p>Access token found for {{ this.selectedCompanyData.name || '-' }}</p>
                                <p>Last refreshed on {{ formatDatetime({ date: accessTokenData.docs[0].updated_at }) }}</p>
                                <div class="uk-inline">
                                    <button
                                        class="uk-button uk-button-small uk-button-secondary uk-margin-small-right" type="button"
                                        v-clipboard:copy="accessTokenData.docs[0].token"
                                        v-clipboard:success="onCopyToken"
                                    >Copy Token</button>
                                    <button
                                        class="uk-button uk-button-small uk-button-primary uk-margin-small-right" type="button"
                                        @click="doGenerateToken"
                                    >Refresh Token</button>
                                    <button
                                        class="uk-button uk-button-small uk-button-danger"
                                        type="button"
                                        @click="doRevokeToken"
                                    >Revoke Token</button>
                                </div>
                            </div>
                            <div v-else>
                                <p>No access token found for {{ this.selectedCompanyData.name || '-' }}</p>
                                <button
                                    type="button"
                                    class="uk-button uk-button-small uk-button-primary"
                                    @click="doGenerateToken"
                                >Generate Token</button>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                type="button"
                                class="uk-button uk-button-primary"
                                @click="hideViewAccessTokenModalBox"
                            >OK</button>
                        </div>
                    </div>
                </div>
                <!-- End view access token modal box -->

                <!-- Company IP whitelist modal box -->
                <div id="company-ip-whitelist-modal-box" class="uk-flex-top company-ip-whitelist-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button
                            class="uk-modal-close-default"
                            type="button"
                            uk-close
                        ></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">IP Whitelist</h2>
                        </div>
                        <div class="uk-modal-body">
                            <table class="uk-table uk-table-small uk-table-hover uk-table-striped">
                                <thead>
                                    <tr>
                                        <th class="uk-text-center">IP Address</th>
                                        <th class="uk-text-center uk-width-small">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="selectedCompanyData.ip_whitelist.length > 0">
                                    <tr v-for="(ip, index) in selectedCompanyData.ip_whitelist" :key="index">
                                        <td class="uk-text-center">{{ ip || '-' }}</td>
                                        <td class="uk-text-center uk-width-small">
                                            <button
                                                type="button"
                                                class="uk-button uk-button-small uk-button-danger"
                                                @click="doRemoveIpWhitelist({ ip })"
                                            >Remove</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="2" />
                            </table>
                            <form @submit.prevent="doAddIpWhitelist" class="uk-flex uk-margin-top">
                                <input
                                    class="uk-input uk-form-small uk-width-expand uk-margin-small-right"
                                    :class="{'uk-form-danger': errors.has('ip_whitelist')}"
                                    name="ip_whitelist"
                                    type="text"
                                    placeholder="Add IP addresses (separate by comma)"
                                    v-model="ip_whitelist"
                                >
                                <button
                                    v-if="buttonAddIpWhitelistLoading"
                                    type="button"
                                    class="uk-button uk-button-small uk-button-default"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.3"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else
                                    type="submit"
                                    class="uk-button uk-button-small"
                                    :class="[disabledButtonAddIpWhitelist ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledButtonAddIpWhitelist"
                                >Add IP(s)</button>
                            </form>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                type="button"
                                class="uk-button uk-button-primary"
                                :disabled="buttonAddIpWhitelistLoading"
                                @click="hideCompanyIpWhitelistModalBox"
                            >OK</button>
                        </div>
                    </div>
                </div>
                <!-- End company IP whitelist modal box -->
            </template>
        </template>
        <no-internet-connection v-else></no-internet-connection>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import draggable from "vuedraggable";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { mapActions, mapGetters } from 'vuex';
import formatter from '@/utils/formatter';
import { getUserLogin } from '@/utils/auth';

export default {
    name: 'Company',
    components: {
        draggable,
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        Loading: () => import('@/components/globals/tables/Loading'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable')
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            user_cred: getUserLogin(),
            internetConnection: true,
            isLoading: false,
            tableLoading: false,
            reorderMode: false,
            buttonSaveModalBoxLoading: false,
            buttonDeleteModalBoxLoading: false,
            modalBoxLoading: false,
            buttonAddIpWhitelistLoading: false,
            buttonRemoveIpWhitelistLoading: false,
            buttonReorderLoading: false,
            disabledFilterAndCreateNewCompany: false,
            actionType: 'create',
            industryOptions: [],
            packageOptions: [
                { name: 'silver' },
                { name: 'gold' },
                { name: 'partner' }
            ],
            partnershipOptions: [
                { name: 'Partner', value: true },
                { name: 'Non Partner', value: false }
            ],
            attendanceMethodOptions: [
                { name: 'Scanned By Employer', value: 0 },
                { name: 'Self-Scan on Office / Warehouse', value: 1 },
                { name: 'Photo Upload', value: 2 }
            ],
            overtimeTypeOptions: [
                { name: 'Overtime Request by Mitra (Bottom Up)', value: 1 },
                { name: 'Overtime Request by SPV (Top Down)', value: 2 },
                { name: 'Overtime Request by Mitra & SPV', value: 3 },
            ],
            companyStatusOptions: [],
            picBdOptions: [],
            picOperationOptions: [],
            superadminOptions: [],
            companyData: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0
            },
            companyLogsData: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0
            },
            accessTokenData: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0
            },
            companyLogoAttachment: {
                url: null,
                file: null
            },
            tempCompanyLogoAttachment: {
                url: null,
                file: null
            },
            ip_whitelist: '',
            selectedCompanyData: {
                id: null,
                name: '',
                seo_url: '',
                company_industry_id: null,
                package: null,
                address: '',
                website: '',
                description: '',
                logo: null,
                is_partner: null,
                attendance_method: [],
                is_test: false,
                overtime_type: null,
                status: null,
                pic_bd_ids: [],
                pic_op_ids: [],
                superadmin_id: null,
                ip_whitelist: []
            },
            bd_exist: false,
            op_exist: false,
            onMoveCompanyItem: true,
            payloadReorderCompanyList: {
                paging_counter: 0,
                company_ids: []
            },
            meta: {
                page: 1,
                limit: 10,
                name: "",
                is_partner: null,
                company_verified: null,
                is_deleted: null,
                test_only: null,
                ascending: null
            },
            dragging: false
        };
    },
    computed: {
        ...mapGetters({
            companies: 'company/companies',
            company_industries: 'company/company_industries',
            company_logs: 'company/company_logs',
            access_tokens: 'open_api/access_tokens'
        }),
        disabledButtonCreateOrEditCompany() {
            let disabled = true;

            if (this.actionType === 'create') {
                if (
                    this.selectedCompanyData.name === '' ||
                    this.selectedCompanyData.seo_url === '' ||
                    this.selectedCompanyData.company_industry_id === null ||
                    this.selectedCompanyData.package === null ||
                    this.selectedCompanyData.address === '' ||
                    this.selectedCompanyData.website === '' ||
                    this.selectedCompanyData.description === '' ||
                    this.selectedCompanyData.is_partner === null
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.actionType === 'edit') {
                if (
                    this.selectedCompanyData.id === null ||
                    this.selectedCompanyData.name === '' ||
                    this.selectedCompanyData.seo_url === '' ||
                    this.selectedCompanyData.company_industry_id === null ||
                    this.selectedCompanyData.package === null ||
                    this.selectedCompanyData.address === '' ||
                    this.selectedCompanyData.website === '' ||
                    this.selectedCompanyData.description === '' ||
                    this.selectedCompanyData.is_partner === null
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else {
                disabled = true;
            }

            return disabled;
        },
        disabledButtonDeleteCompany() {
            let disabled = true;
            if (this.selectedCompanyData.id === null) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        disabledButtonUpdateCompanyStatus() {
            let disabled = true;

            if (this.actionType === 'updateCompanyStatus') {
                if (
                    this.selectedCompanyData.id === null ||
                    this.selectedCompanyData.status === null
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.actionType === 'changeCompanyPic') {
                if (
                    this.selectedCompanyData.id === null
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else {
                disabled = true;
            }

            return disabled;
        },
        disabledButtonAddIpWhitelist() {
            let disabled = true;

            if (this.ip_whitelist === '') {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        }
    },
    watch: {
        async meta() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.tableLoading = true;
                    await this.setCompanyData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-company-modal-box')) window.UIkit.modal('.create-or-edit-company-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-company-modal-box')) window.UIkit.modal('.confirmation-delete-company-modal-box').$destroy(true);
        if (window.UIkit.modal('.company-logs-modal-box')) window.UIkit.modal('.company-logs-modal-box').$destroy(true);
        if (window.UIkit.modal('.update-company-status-modal-box')) window.UIkit.modal('.update-company-status-modal-box').$destroy(true);
        if (window.UIkit.modal('.change-company-pic-modal-box')) window.UIkit.modal('.change-company-pic-modal-box').$destroy(true);
        if (window.UIkit.modal('.view-access-token-modal-box')) window.UIkit.modal('.view-access-token-modal-box').$destroy(true);
        if (window.UIkit.modal('.company-ip-whitelist-modal-box')) window.UIkit.modal('.company-ip-whitelist-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.isLoading = true;
                await Promise.all([
                    this.setCompanyData(),
                    this.setIndustryOptions(),
                    this.setPicBdOptions(),
                    this.setPicOperationOptions(),
                    this.setSuperadminOptions()
                ]);
            } else {
                this.internetConnection = false;
            }
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getCompanies: 'company/getCompanies',
            getCompanyIndustries: 'company/getCompanyIndustries',
            createCompany: 'company/createCompany',
            updateCompany: 'company/updateCompany',
            uploadImage: 'file/uploadImage',
            deleteCompany: 'company/deleteCompany',
            getCompanyLog: 'company/getCompanyLog',
            listsUser: 'user/ListsUser',
            updateStatusCompany: 'company/updateStatusCompany',
            getAccessTokens: 'open_api/getAccessTokens',
            generateAccessToken: 'open_api/generateAccessToken',
            revokeAccessToken: 'open_api/revokeAccessToken',
            updateWhitelist: 'company/updateWhitelist',
            reorderCompanyList: 'company/reorderCompanyList'
        }),
        async setCompanyData() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getCompanies(this.meta);
                    this.companyData = this.companies;
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setIndustryOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getCompanyIndustries();
                    if (this.company_industries && this.company_industries.docs.length > 0) {
                        this.industryOptions = this.company_industries.docs.map(obj => ({ name: obj.name, value: obj._id }));
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setPicBdOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.picBdOptions = await this.listsUser({ roles: 'admin-bd', scope:'all' });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setPicOperationOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.picOperationOptions = await this.listsUser({ roles: 'admin-operation', scope:'all' });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setSuperadminOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.superadminOptions = await this.listsUser({ roles: 'superadmin-product', scope:'all' });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        setCompanyStatusOptions({ status }) {
            switch (status) {
            case 0:
                this.companyStatusOptions = [
                    { name: "On Progress", value: 1 },
                    { name: "Canceled", value: 5 },
                ];
                break;
            case 1:
                this.companyStatusOptions = [
                    { name: "Waiting Contract", value: 2 },
                    { name: "Contract", value: 3 },
                    { name: "Active", value: 4 },
                    { name: "Canceled", value: 5 },
                ];
                break;
            case 2:
                this.companyStatusOptions = [
                    { name: "Contract", value: 3 },
                    { name: "Active", value: 4 },
                    { name: "Canceled", value: 5 },
                ];
                break;
            case 3:
                this.companyStatusOptions = [
                    { name: "Active", value: 4 },
                    { name: "Canceled", value: 5 },
                ];
                break;
            case 4:
                this.companyStatusOptions = [
                    { name: "Inactive", value: 6 },
                ];
                break;
            case 5:
                this.companyStatusOptions = [
                    { name: "Inactive", value: 6 },
                    { name: "On Progress", value: 1 },
                ];
                break;
            case 6:
                this.companyStatusOptions = [
                    { name: "Incoming", value: 0 },
                ];
                break;
            default:
                this.companyStatusOptions = [];
                break;
            }
        },
        async setCompanyLogsData({ companyId }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getCompanyLog({ company_id: companyId });
                    this.companyLogsData = this.company_logs;
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setAccessTokenData({ companyId }) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    await this.getAccessTokens({ company_id: companyId });
                    this.accessTokenData = this.access_tokens;
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterCompany() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.tableLoading = true;
                    await this.setCompanyData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async showCreateOrEditCompanyModalBox({
            actionType, id, name, seoUrl, companyIndustryId, packageData, address, website, description, logo, isPartner, attendanceMethod, isTest, overtimeType
        }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetSelectedCompanyData();
                    this.resetCompanyLogoAttachment();
                    this.resetTempCompanyLogoAttachment();
                    this.errors.clear();
                    if (actionType === 'edit') {
                        this.fillSelectedCompanyData({
                            id,
                            name,
                            seoUrl,
                            companyIndustryId,
                            packageData,
                            address,
                            website,
                            description,
                            logo,
                            isPartner,
                            attendanceMethod,
                            isTest,
                            overtimeType,
                            status: null,
                            picBds: [],
                            picOps: [],
                            superadminId: null,
                            ipWhitelist: []
                        });
                    }
                    await window.UIkit.modal('#create-or-edit-company-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationDeleteCompanyModalBox({ actionType, id, name, description }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetSelectedCompanyData();
                    this.resetCompanyLogoAttachment();
                    this.resetTempCompanyLogoAttachment();
                    this.fillSelectedCompanyData({
                        id,
                        name,
                        seoUrl: '',
                        companyIndustryId: null,
                        packageData: null,
                        address: '',
                        website: '',
                        description,
                        logo: null,
                        isPartner: null,
                        attendanceMethod: [],
                        isTest: false,
                        overtimeType: null,
                        status: null,
                        picBds: [],
                        picOps: [],
                        superadminId: null,
                        ipWhitelist: []
                    });
                    this.errors.clear();
                    await window.UIkit.modal('#confirmation-delete-company-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showCompanyLogsModalBox({ actionType, id }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.modalBoxLoading = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetCompanyLogsData();
                    await window.UIkit.modal('#company-logs-modal-box').show();
                    await this.setCompanyLogsData({ companyId: id });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxLoading = false;
            }
        },
        async hideCompanyLogsModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.resetCompanyLogsData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#company-logs-modal-box').hide();
            }
        },
        async showUpdateCompanyStatusModalBox({ actionType, id, companyStatus, picBds, picOps }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.companyStatusOptions = [];
                    this.setCompanyStatusOptions({ status: companyStatus });
                    this.resetSelectedCompanyData();
                    this.resetCompanyLogoAttachment();
                    this.resetTempCompanyLogoAttachment();
                    this.fillSelectedCompanyData({
                        id,
                        name: '',
                        seoUrl: '',
                        companyIndustryId: null,
                        packageData: null,
                        address: '',
                        website: '',
                        description: '',
                        logo: null,
                        isPartner: null,
                        attendanceMethod: [],
                        isTest: false,
                        overtimeType: null,
                        status: companyStatus,
                        picBds,
                        picOps,
                        superadminId: null,
                        ipWhitelist: []
                    });
                    this.errors.clear();
                    await window.UIkit.modal('#update-company-status-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showChangeCompanyPicModalBox({ actionType, id, companyStatus, picBds, picOps, superadminId }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetSelectedCompanyData();
                    this.resetCompanyLogoAttachment();
                    this.resetTempCompanyLogoAttachment();
                    this.fillSelectedCompanyData({
                        id,
                        name: '',
                        seoUrl: '',
                        companyIndustryId: null,
                        packageData: null,
                        address: '',
                        website: '',
                        description: '',
                        logo: null,
                        isPartner: null,
                        attendanceMethod: [],
                        isTest: false,
                        overtimeType: null,
                        status: companyStatus,
                        picBds,
                        picOps,
                        superadminId,
                        ipWhitelist: []
                    });
                    this.errors.clear();
                    await window.UIkit.modal('#change-company-pic-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showViewAccessTokenModalBox({ actionType, id, name }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.modalBoxLoading = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    await window.UIkit.modal('#view-access-token-modal-box').show();
                    await this.setAccessTokenData({ companyId: id });
                    this.resetSelectedCompanyData();
                    this.resetCompanyLogoAttachment();
                    this.resetTempCompanyLogoAttachment();
                    this.fillSelectedCompanyData({
                        id,
                        name,
                        seoUrl: '',
                        companyIndustryId: null,
                        packageData: null,
                        address: '',
                        website: '',
                        description: '',
                        logo: null,
                        isPartner: null,
                        attendanceMethod: [],
                        isTest: false,
                        overtimeType: null,
                        status: null,
                        picBds: [],
                        picOps: [],
                        superadminId: null,
                        ipWhitelist: []
                    });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxLoading = false;
            }
        },
        async hideViewAccessTokenModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.resetAccessTokenData();
                    this.resetSelectedCompanyData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#view-access-token-modal-box').hide();
            }
        },
        async showCompanyIpWhitelistModalBox({ actionType, id, ipWhitelist }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetSelectedCompanyData();
                    this.ip_whitelist = '';
                    this.fillSelectedCompanyData({
                        id,
                        name: '',
                        seoUrl: '',
                        companyIndustryId: null,
                        packageData: null,
                        address: '',
                        website: '',
                        description: '',
                        logo: null,
                        isPartner: null,
                        attendanceMethod: [],
                        isTest: false,
                        overtimeType: null,
                        status: null,
                        picBds: [],
                        picOps: [],
                        superadminId: null,
                        ipWhitelist
                    });
                    await window.UIkit.modal('#company-ip-whitelist-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideCompanyIpWhitelistModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await window.UIkit.modal('#company-ip-whitelist-modal-box').hide();
                    this.ip_whitelist = '';
                    this.resetSelectedCompanyData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async createOrEditCompany() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (this.actionType === 'create') {
                        await this.doCreateCompany();
                    } else if (this.actionType === 'edit') {
                        this.doEditCompany();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async reorderCompanies() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.buttonReorderLoading = true;

                    this.resetPayloadReorderCompanyList();
                    this.toggleReorderMode({ value: true });

                    this.tableLoading = true;
                    this.meta.name = '';
                    this.meta.is_partner = null;
                    this.meta.company_verified = null;
                    this.meta.is_deleted = null;
                    this.meta.test_only = null;
                    this.meta.ascending = null;
                    await this.setCompanyData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                this.toggleReorderMode({ value: false });
                notificationDanger(error);
            } finally {
                this.buttonReorderLoading = false;
                this.tableLoading = false;
                this.disabledFilterAndCreateNewCompany = true;
                this.onMoveCompanyItem = true;
            }
        },  
        async doCancelReorderCompanyList() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.buttonReorderLoading = true;

                    this.hideDropdown();
                    this.resetPayloadReorderCompanyList();

                    this.tableLoading = true;
                    await this.setCompanyData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonReorderLoading = false;
                this.tableLoading = false;
                this.disabledFilterAndCreateNewCompany = false;
                this.onMoveCompanyItem = true;
                this.toggleReorderMode({ value: false });
            }
        },
        async doSaveReorderCompanyList() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.buttonReorderLoading = true;

                    const response = await this.reorderCompanyList(this.payloadReorderCompanyList);
                    if (response && response.status === 'OK') {
                        this.resetPayloadReorderCompanyList();
                        this.hideDropdown();
                        this.toggleReorderMode({ value: false });

                        this.tableLoading = true;
                        await this.setCompanyData();
                        notificationSuccess('Company list reordered successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonReorderLoading = false;
                this.onMoveCompanyItem = true;
                this.tableLoading = false;
                this.disabledFilterAndCreateNewCompany = false;
            }
        },
        async doCreateCompany() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const validate = await this.$validator.validateAll();
                    if (!validate || this.$validator.errors.any()) return;

                    this.buttonSaveModalBoxLoading = true;

                    if (this.companyLogoAttachment.url && this.companyLogoAttachment.file) {
                        await this.doUploadCompanyLogo();
                    } else {
                        this.selectedCompanyData.logo = null;
                    }

                    const payloads = {
                        company_industry_id: null,
                        name: this.selectedCompanyData.name,
                        seo_url: this.selectedCompanyData.seo_url,
                        address: this.selectedCompanyData.address,
                        website: this.selectedCompanyData.website,
                        description: this.selectedCompanyData.description,
                        package: null,
                        is_partner: null,
                        is_test: this.selectedCompanyData.is_test,
                        attendance_method: [],
                        logo: this.selectedCompanyData.logo,
                    };

                    if (this.selectedCompanyData.company_industry_id && this.selectedCompanyData.company_industry_id.value) {
                        payloads.company_industry_id = this.selectedCompanyData.company_industry_id.value;
                    } else {
                        payloads.company_industry_id = null;
                    }

                    if (this.selectedCompanyData.package && this.selectedCompanyData.package.name) {
                        payloads.package = this.selectedCompanyData.package.name;
                    } else {
                        payloads.package = null;
                    }

                    if (this.selectedCompanyData.is_partner && this.selectedCompanyData.is_partner.value) {
                        payloads.is_partner = this.selectedCompanyData.is_partner.value;
                    } else {
                        payloads.is_partner = null;
                    }

                    if (this.selectedCompanyData.attendance_method.length > 0) {
                        const attendanceMetods = [];
                        this.selectedCompanyData.attendance_method.forEach((element) => {
                            attendanceMetods.push(element.value);
                        });
                        payloads.attendance_method = attendanceMetods.sort();
                    } else {
                        payloads.attendance_method = [];
                    }

                    if (this.selectedCompanyData.overtime_type) {
                        payloads.overtime_type = this.selectedCompanyData.overtime_type.value;
                    } else {
                        payloads.overtime_type = 1;
                    }

                    const response = await this.createCompany(payloads);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#create-or-edit-company-modal-box').hide();
                        this.resetSelectedCompanyData();
                        this.resetCompanyLogoAttachment();
                        this.resetTempCompanyLogoAttachment();
                        this.errors.clear();
                        this.tableLoading = true;
                        await this.setCompanyData();
                        notificationSuccess('Company created successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonSaveModalBoxLoading = false;
                this.tableLoading = false;
            }
        },
        async doEditCompany() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const validate = await this.$validator.validateAll();
                    if (!validate || this.$validator.errors.any()) return;

                    this.buttonSaveModalBoxLoading = true;

                    if (this.companyLogoAttachment.url && this.companyLogoAttachment.file) {
                        if (
                            this.companyLogoAttachment.url !== this.tempCompanyLogoAttachment.url &&
                            this.companyLogoAttachment.file !== this.tempCompanyLogoAttachment.file
                        ) {
                            await this.doUploadCompanyLogo();
                        }
                    } else {
                        this.selectedCompanyData.logo = null;
                    }

                    const payloads = {
                        id: this.selectedCompanyData.id,
                        company_industry_id: null,
                        name: this.selectedCompanyData.name,
                        seo_url: this.selectedCompanyData.seo_url,
                        address: this.selectedCompanyData.address,
                        website: this.selectedCompanyData.website,
                        description: this.selectedCompanyData.description,
                        package: null,
                        is_partner: null,
                        is_test: this.selectedCompanyData.is_test,
                        attendance_method: [],
                        logo: this.selectedCompanyData.logo,
                    };

                    if (this.selectedCompanyData.company_industry_id && this.selectedCompanyData.company_industry_id.value) {
                        payloads.company_industry_id = this.selectedCompanyData.company_industry_id.value;
                    } else {
                        payloads.company_industry_id = null;
                    }

                    if (this.selectedCompanyData.package && this.selectedCompanyData.package.name) {
                        payloads.package = this.selectedCompanyData.package.name;
                    } else {
                        payloads.package = null;
                    }

                    if (this.selectedCompanyData.is_partner && this.selectedCompanyData.is_partner.value) {
                        payloads.is_partner = this.selectedCompanyData.is_partner.value;
                    } else {
                        payloads.is_partner = null;
                    }

                    if (this.selectedCompanyData.attendance_method.length > 0) {
                        const attendanceMetods = [];
                        this.selectedCompanyData.attendance_method.forEach((element) => {
                            attendanceMetods.push(element.value);
                        });
                        payloads.attendance_method = attendanceMetods.sort();
                    } else {
                        payloads.attendance_method = [];
                    }

                    if (this.selectedCompanyData.overtime_type) {
                        payloads.overtime_type = this.selectedCompanyData.overtime_type.value;
                    } else {
                        payloads.overtime_type = 1;
                    }

                    const response = await this.updateCompany(payloads);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#create-or-edit-company-modal-box').hide();

                        this.resetSelectedCompanyData();
                        this.resetCompanyLogoAttachment();
                        this.resetTempCompanyLogoAttachment();
                        this.errors.clear();

                        this.tableLoading = true;
                        await this.setCompanyData();

                        notificationSuccess('Company updated successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonSaveModalBoxLoading = false;
                this.tableLoading = false;
            }
        },
        async doDeleteCompany() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.buttonDeleteModalBoxLoading = true;

                    const response = await this.deleteCompany(this.selectedCompanyData.id);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#confirmation-delete-company-modal-box').hide();

                        this.resetSelectedCompanyData();
                        this.resetCompanyLogoAttachment();
                        this.resetTempCompanyLogoAttachment();
                        this.errors.clear();

                        this.tableLoading = true;
                        await this.setCompanyData();

                        notificationSuccess('Company deleted successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonDeleteModalBoxLoading = false;
                this.tableLoading = false;
            }
        },
        async doUploadCompanyLogo() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    const formData = new FormData();
                    formData.append("file", this.companyLogoAttachment.file);
                    const response = await this.uploadImage({ folder: 'company-logo', category: this.selectedCompanyData.name, data: formData });
                    if (response && response.file_path) {
                        this.selectedCompanyData.logo = response.file_path;
                    } else {
                        this.selectedCompanyData.logo = null;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doUpdateCompanyStatus() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.buttonSaveModalBoxLoading = true;

                    const payloads = {
                        id: this.selectedCompanyData.id,
                        status: null,
                        pic_bd_ids: [],
                        pic_op_ids: [],
                        superadmin_id: null
                    };

                    if (this.selectedCompanyData.status !== null && this.selectedCompanyData.status.value !== null) {
                        payloads.status = this.selectedCompanyData.status.value;
                    } else {
                        payloads.status = null;
                    }

                    if (this.selectedCompanyData.pic_bd_ids.length > 0) {
                        payloads.pic_bd_ids = this.selectedCompanyData.pic_bd_ids.map((element) => element._id);
                    } else {
                        payloads.pic_bd_ids = [];
                    }

                    if (this.selectedCompanyData.pic_op_ids.length > 0) {
                        payloads.pic_op_ids = this.selectedCompanyData.pic_op_ids.map((element) => element._id);
                    } else {
                        payloads.pic_op_ids = [];
                    }

                    if (this.selectedCompanyData.superadmin_id && this.selectedCompanyData.superadmin_id._id) {
                        payloads.superadmin_id = this.selectedCompanyData.superadmin_id._id;
                    } else {
                        payloads.superadmin_id = null;
                    }

                    const response = await this.updateStatusCompany(payloads);
                    if (response && response.status === 'OK') {
                        if (this.actionType === 'updateCompanyStatus') {
                            await window.UIkit.modal('#update-company-status-modal-box').hide();
                        } else if (this.actionType === 'changeCompanyPic') {
                            await window.UIkit.modal('#change-company-pic-modal-box').hide();
                        }

                        this.resetSelectedCompanyData();
                        this.resetCompanyLogoAttachment();
                        this.resetTempCompanyLogoAttachment();
                        this.errors.clear();

                        this.tableLoading = true;
                        await this.setCompanyData();

                        if (this.actionType === 'updateCompanyStatus') {
                            notificationSuccess('Company status updated successfully');
                        } else if (this.actionType === 'changeCompanyPic') {
                            notificationSuccess('PIC updated successfully');
                        }
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonSaveModalBoxLoading = false;
                this.tableLoading = false;
            }
        },
        async doGenerateToken() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const response = await this.generateAccessToken({
                        company_id: this.selectedCompanyData.id,
                        company: this.selectedCompanyData.name.toLowerCase() === 'shipper' ? 1 : 0,
                        status: null
                    });

                    if (response && response.status === 'OK') {
                        await this.setAccessTokenData({ companyId: this.selectedCompanyData.id });
                        notificationSuccess(`Access token generated for ${this.selectedCompanyData.name}`);
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doRevokeToken() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    if (!this.accessTokenData.docs) return;
                    const response = await this.revokeAccessToken(this.accessTokenData.docs[0]._id);

                    if (response && response.status === 'OK') {
                        await this.setAccessTokenData({ companyId: this.selectedCompanyData.id });
                        notificationSuccess(`Access token revoked`);
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doAddIpWhitelist() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.buttonAddIpWhitelistLoading = true;

                    const response = await this.updateWhitelist({
                        actionIsDelete: false,
                        id: this.selectedCompanyData.id,
                        ip_whitelist: this.ip_whitelist.split(' ').join('').split(',').filter(Boolean)
                    });

                    if (response && response.status === 'OK') {
                        this.selectedCompanyData.ip_whitelist = response.result.ip_whitelist;
                        this.ip_whitelist = '';
                        this.tableLoading = true;
                        await this.setCompanyData();
                        notificationSuccess(`IP address whitelist updated`);
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonAddIpWhitelistLoading = false;
                this.tableLoading = false;
            }
        },
        async doRemoveIpWhitelist({ ip }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const response = await this.updateWhitelist({
                        actionIsDelete: true,
                        id: this.selectedCompanyData.id,
                        ip_whitelist: ip ? [ip] : this.ip_whitelist.split(' ').join('').split(',').filter(Boolean)
                    });

                    if (response && response.status === 'OK') {
                        this.selectedCompanyData.ip_whitelist = response.result.ip_whitelist;
                        this.tableLoading = true;
                        await this.setCompanyData();
                        notificationSuccess(`IP address whitelist updated`);
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        onChangeDraggedCompany(event) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.onMoveCompanyItem = false;

                if (event && event.moved) {
                    this.resetPayloadReorderCompanyList();
                    this.fillPayloadReorderCompanyList({ pagingCounter: this.companyData.pagingCounter, companyList: this.companyData.docs });
                } else {
                    this.resetPayloadReorderCompanyList();
                }
            } else {
                this.internetConnection = false;
            }
        },
        gotoCompanyDetail({ companyId }) {
            this.hideDropdown();
            this.$router.push(`/admin/mitra/company/detail/${companyId}`);
        },
        onCopy(e) {
            this.hideDropdown();
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        onCopyToken() {
            this.hideDropdown();
            notificationSuccess('Access token copied to clipboard');
        },
        toggleReorderMode({ value }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.reorderMode = value;
            } else {
                this.internetConnection = false;
            }
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
        },
        triggerUploadCompanyLogo() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$refs.companyLogo.click();
            } else {
                this.internetConnection = false;
            }
        },
        previewCompanyLogo({ event }) {
            if (event.target.files && event.target.files.length > 0) {
                this.companyLogoAttachment.url = URL.createObjectURL(event.target.files[0]);
                this.companyLogoAttachment.file = event.target.files[0];
            }
        },
        fillPayloadReorderCompanyList({ pagingCounter, companyList }) {
            this.payloadReorderCompanyList.paging_counter = pagingCounter;

            if (companyList && companyList.length > 0) {
                const distinctCompanyIds = [...new Set(companyList.map(item => String(item._id)))];
                this.payloadReorderCompanyList.company_ids = distinctCompanyIds;
            } else {
                this.payloadReorderCompanyList.company_ids = [];
            }
        },
        fillSelectedCompanyData({
            id,
            name,
            seoUrl,
            companyIndustryId,
            packageData,
            address,
            website,
            description,
            logo,
            isPartner,
            attendanceMethod,
            isTest,
            overtimeType,
            status,
            picBds,
            picOps,
            superadminId,
            ipWhitelist
        }) {
            this.selectedCompanyData.id = id;
            this.selectedCompanyData.name = name;
            this.selectedCompanyData.seo_url = seoUrl;
            this.selectedCompanyData.address = address;
            this.selectedCompanyData.website = website;
            this.selectedCompanyData.description = description;
            this.selectedCompanyData.is_test = isTest;
            this.selectedCompanyData.ip_whitelist = ipWhitelist;

            if (companyIndustryId && this.industryOptions.length > 0) {
                this.selectedCompanyData.company_industry_id = this.industryOptions.find((industry) => (
                    industry.value === companyIndustryId
                ));
            } else {
                this.selectedCompanyData.company_industry_id = null;
            }

            if (packageData) {
                this.selectedCompanyData.package = { name: packageData };
            } else {
                this.selectedCompanyData.packageData = null;
            }

            if (isPartner && this.partnershipOptions.length > 0) {
                this.selectedCompanyData.is_partner = this.partnershipOptions.find((partner) => (
                    partner.value === isPartner
                ));
            } else {
                this.selectedCompanyData.is_partner = null;
            }

            if (attendanceMethod && attendanceMethod.length > 0 && this.attendanceMethodOptions.length > 0) {
                let attendances = [];
                attendanceMethod.forEach((element) => {
                    attendances.push(this.attendanceMethodOptions.find((attendance) => (
                        attendance.value === element
                    )));
                });

                if (attendances.length > 0) {
                    this.selectedCompanyData.attendance_method = attendances;
                } else {
                    this.selectedCompanyData.attendance_method = []; 
                }
            } else {
                this.selectedCompanyData.attendance_method = [];
            }

            if (logo && logo.file_url && logo.file_path) {
                this.companyLogoAttachment.url = logo.file_url;
                this.companyLogoAttachment.file = logo.file_path;
                this.tempCompanyLogoAttachment.url = logo.file_url;
                this.tempCompanyLogoAttachment.file = logo.file_path;
                this.selectedCompanyData.logo = logo.file_path;
            } else {
                this.resetCompanyLogoAttachment();
                this.resetTempCompanyLogoAttachment();
                this.selectedCompanyData.logo = null;
            }

            if (this.companyStatusOptions.length > 0) {
                if (status !== null) {
                    const findStatusData = this.companyStatusOptions.find((element) => (
                        element.value == status
                    ));

                    if (findStatusData) {
                        this.selectedCompanyData.status = findStatusData;
                    } else {
                        this.selectedCompanyData.status = null;
                    }
                } else {
                    this.selectedCompanyData.status = null;
                }
            } else {
                if (status !== null) {
                    this.selectedCompanyData.status = status;
                } else {
                    this.selectedCompanyData.status = null;
                }
            }

            if (picBds && picBds.length > 0) {
                const picData = [];
                for (const pic of picBds) {
                    const findData = this.picBdOptions.find((element) => (
                        element._id === pic._id
                    ));

                    if (findData) {
                        picData.push(findData);
                    }
                }

                if (picData.length > 0) {
                    this.selectedCompanyData.pic_bd_ids = picData;
                    this.bd_exist = true;
                } else {
                    this.selectedCompanyData.pic_bd_ids = [];
                }
            } else {
                this.selectedCompanyData.pic_bd_ids = [];
            }

            if (picOps && picOps.length > 0) {
                const picData = [];
                for (const pic of picOps) {
                    const findData = this.picOperationOptions.find((element) => (
                        element._id === pic._id
                    ));

                    if (findData) {
                        picData.push(findData);
                    }
                }

                if (picData.length > 0) {
                    this.selectedCompanyData.pic_op_ids = picData;
                    this.op_exist = true;
                } else {
                    this.selectedCompanyData.pic_op_ids = [];
                }
            } else {
                this.selectedCompanyData.pic_op_ids = [];
            }

            if (superadminId) {
                this.selectedCompanyData.superadmin_id = this.superadminOptions.find((element) => (
                    element._id === superadminId
                ));
            } else {
                this.selectedCompanyData.superadmin_id = null;
            }

            const otType = overtimeType || 1;
            this.selectedCompanyData.overtime_type = this.overtimeTypeOptions.find((ot) => (
                ot.value === otType
            ));
        },
        resetPayloadReorderCompanyList() {
            this.payloadReorderCompanyList.paging_counter = 0;
            this.payloadReorderCompanyList.company_ids = [];
        },
        resetSelectedCompanyData() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.selectedCompanyData.id = null;
                this.selectedCompanyData.name = '';
                this.selectedCompanyData.seo_url = '';
                this.selectedCompanyData.company_industry_id = null;
                this.selectedCompanyData.package = null;
                this.selectedCompanyData.address = '';
                this.selectedCompanyData.website = '';
                this.selectedCompanyData.description = '';
                this.selectedCompanyData.is_partner = null;
                this.selectedCompanyData.attendance_method = [];
                this.selectedCompanyData.is_test = false;
                this.selectedCompanyData.overtime_type = null;
                this.selectedCompanyData.status = null;
                this.selectedCompanyData.pic_bd_ids = [];
                this.selectedCompanyData.pic_op_ids = [];
                this.selectedCompanyData.superadmin_id = null;
                this.selectedCompanyData.ip_whitelist = [];
                this.bd_exist = false;
                this.op_exist = false;
            } else {
                this.internetConnection = false;
            }
        },
        resetCompanyLogoAttachment() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.companyLogoAttachment.url = null;
                this.companyLogoAttachment.file = null;
            } else {
                this.internetConnection = false;
            }
        },
        resetTempCompanyLogoAttachment() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.tempCompanyLogoAttachment.url = null;
                this.tempCompanyLogoAttachment.file = null;
            } else {
                this.internetConnection = false;
            }
        },
        resetCompanyLogsData() {
            this.companyLogsData.docs = [];
            this.companyLogsData.totalDocs = 0;
            this.companyLogsData.pagingCounter = 0;
        },
        resetAccessTokenData() {
            this.accessTokenData.docs = [];
            this.accessTokenData.totalDocs = 0;
            this.accessTokenData.pagingCounter = 0;
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        statusColor({ status }){
            switch (status) {
            case 0:
                return "background-color: #FFFFFF; color: #999999";
            case 1:
                return "background-color: #FEBE10; color: #FFFFFF";
            case 2:
                return "background-color: #2DD8D3; color: #FFFFFF";
            case 3:
                return "background-color: #0275D8; color: #FFFFFF";
            case 4:
                return "background-color: #00cc00; color: #FFFFFF";
            case 5:
                return "background-color: #FF3737; color: #FFFFFF";
            case 6:
                return "background-color: #C4C4C4; color: #FFFFFF";
            }
        },
        formatDatetime({ date }) {
            if (!date) {
                return null;
            } else {
                return formatter.datetimeComplete(date);
            }
        },
        statusLabel({ status }){
            switch (status) {
            case 0:
                return "Incoming";
            case 1:
                return "On Progress";
            case 2:
                return "Waiting Contract";
            case 3:
                return "Contract";
            case 4:
                return "Active";
            case 5:
                return "Canceled";
            case 6:
                return "Inactive";
            }
        },
        attendanceMethodLabel({ logData }) {
            let data = [];
            for (const id of logData) {
                for (const method of this.attendanceMethodOptions) {
                    if (id == method.value) {
                        data.push(method.name);
                    }
                }
            }
            return data;
        },
        industryLabel({ companyIndustryId }){
            for (const industry of this.industryOptions) {
                if (companyIndustryId == industry.value) {
                    return industry.name;
                }
            }
        },
        picBDLabel({ bdId }){
            for (const bd of this.picBdOptions) {
                if (bdId == bd.id) {
                    return bd.fullname;
                }
            }
        },
        picOPLabel({ opId }){
            for (const op of this.picOperationOptions) {
                if (opId == op.id) {
                    return op.fullname;
                }
            }
        },
        picBDsLabel({ data }){
            let label = '';
            for (const id of data) {
                const exist = this.picBdOptions.find(el => el._id == id);
                if (label.length > 0 && exist) {
                    label += `, ${exist.fullname}`;
                } else if (label.length ==  0 && exist) {
                    label += exist.fullname;
                }
            }
            return label;
        },
        picOPsLabel({ data }){
            let label = '';
            for (const id of data) {
                const exist = this.picOperationOptions.find(el => el._id == id);
                if (label.length > 0 && exist) {
                    label += `, ${exist.fullname}`;
                } else if (label.length ==  0 && exist) {
                    label += exist.fullname;
                }
            }
            return label;
        },
        superadminLabel({ superadminId }){
            for (const sa of this.superadminOptions) {
                if (superadminId == sa.id) {
                    return sa.fullname;
                }
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        changeLimit(e) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;

                this.meta = {
                    ...this.meta,
                    limit: e.target.value
                };
            } else {
                this.internetConnection = false;
            }
        },
        changePage(value) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;

                this.meta = {
                    ...this.meta,
                    page: value
                };
            } else {
                this.internetConnection = false;
            }
        }
    }
};
</script>

<style scoped>
.box {
    background: #FFFFFF;
    cursor: move;
}
.drag-drop-icon {
    width: 31px;
    height: 31px;
}
.icon-close-button {
    background: white;
    border: 1px solid black;
    outline: none;
    border-radius: 100%;
    cursor: pointer;
    color: black;
    padding: 10px;
    margin: 10px;
    box-shadow: 1px;
}
.icon {
    width: 8px;
    height: auto;
}
.image-preview {
    border: 1px solid black;
}
</style>
